<template>
    <div>
        <h2>Nieuwe beoordeling</h2>
        <div class="row">
            <div class="col-sm center">
                <div class="" v-if="sendSucces == true">
                    Beoordeling opgeslagen!
                    <br>
                    <span class="btn" @click="backToForm()">Nieuwe beoordeling</span>
                </div>
                <form id="review-form" v-else>
                    <select name="club" v-model="newReview.club">
                        <option disabled>Selecteer club</option>
                        <option v-for="club in clubs" :key="club.id" :value="club.id">
                            {{ club.name }}
                        </option>
                    </select>
                    <input type="text" required name="reviewer_name" v-model="newReview.reviewer_name" placeholder="Naam" />
                    <label for="rating_pres">👀 Presentatie</label>
                    <div class="review-items row">
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_pres >= 1 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_pres" v-model="newReview.rating_pres" value="1" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_pres >= 2 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_pres" v-model="newReview.rating_pres" value="2" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_pres >= 3 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_pres" v-model="newReview.rating_pres" value="3" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_pres >= 4 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_pres" v-model="newReview.rating_pres" value="4" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_pres >= 5 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_pres" v-model="newReview.rating_pres" value="5" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_pres >= 6 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_pres" v-model="newReview.rating_pres" value="6" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_pres >= 7 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_pres" v-model="newReview.rating_pres" value="7" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_pres >= 8 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_pres" v-model="newReview.rating_pres" value="8" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_pres >= 9 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_pres" v-model="newReview.rating_pres" value="9" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_pres >= 10 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_pres" v-model="newReview.rating_pres" value="10" />
                        </div>
                    </div>

                    <label for="rating_temp">🌡 Temperatuur</label>
                    <div class="review-items row">
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_temp >= 1 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_temp" v-model="newReview.rating_temp" value="1" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_temp >= 2 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_temp" v-model="newReview.rating_temp" value="2" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_temp >= 3 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_temp" v-model="newReview.rating_temp" value="3" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_temp >= 4 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_temp" v-model="newReview.rating_temp" value="4" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_temp >= 5 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_temp" v-model="newReview.rating_temp" value="5" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_temp >= 6 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_temp" v-model="newReview.rating_temp" value="6" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_temp >= 7 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_temp" v-model="newReview.rating_temp" value="7" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_temp >= 8 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_temp" v-model="newReview.rating_temp" value="8" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_temp >= 9 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_temp" v-model="newReview.rating_temp" value="9" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_temp >= 10 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_temp" v-model="newReview.rating_temp" value="10" />
                        </div>
                    </div>

                    <label for="rating_tast">👅 Smaak</label>
                    <div class="review-items row">
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_tast >= 1 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_tast" v-model="newReview.rating_tast" value="1" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_tast >= 2 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_tast" v-model="newReview.rating_tast" value="2" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_tast >= 3 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_tast" v-model="newReview.rating_tast" value="3" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_tast >= 4 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_tast" v-model="newReview.rating_tast" value="4" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_tast >= 5 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_tast" v-model="newReview.rating_tast" value="5" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_tast >= 6 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_tast" v-model="newReview.rating_tast" value="6" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_tast >= 7 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_tast" v-model="newReview.rating_tast" value="7" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_tast >= 8 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_tast" v-model="newReview.rating_tast" value="8" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_tast >= 9 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_tast" v-model="newReview.rating_tast" value="9" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_tast >= 10 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_tast" v-model="newReview.rating_tast" value="10" />
                        </div>
                    </div>

                    <label for="rating_struc">👨‍🍳 Structuur</label>
                    <div class="review-items row">
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_struc >= 1 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_struc" v-model="newReview.rating_struc" value="1" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_struc >= 2 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_struc" v-model="newReview.rating_struc" value="2" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_struc >= 3 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_struc" v-model="newReview.rating_struc" value="3" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_struc >= 4 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_struc" v-model="newReview.rating_struc" value="4" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_struc >= 5 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_struc" v-model="newReview.rating_struc" value="5" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_struc >= 6 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_struc" v-model="newReview.rating_struc" value="6" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_struc >= 7 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_struc" v-model="newReview.rating_struc" value="7" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_struc >= 8 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_struc" v-model="newReview.rating_struc" value="8" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_struc >= 9 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_struc" v-model="newReview.rating_struc" value="9" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_struc >= 10 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_struc" v-model="newReview.rating_struc" value="10" />
                        </div>
                    </div>

                    <label for="rating_price">💰 Prijs/kwaliteit</label>
                    <div class="review-items row">
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_price >= 1 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_price" v-model="newReview.rating_price" value="1" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_price >= 2 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_price" v-model="newReview.rating_price" value="2" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_price >= 3 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_price" v-model="newReview.rating_price" value="3" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_price >= 4 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_price" v-model="newReview.rating_price" value="4" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_price >= 5 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_price" v-model="newReview.rating_price" value="5" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_price >= 6 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_price" v-model="newReview.rating_price" value="6" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_price >= 7 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_price" v-model="newReview.rating_price" value="7" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_price >= 8 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_price" v-model="newReview.rating_price" value="8" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_price >= 9 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_price" v-model="newReview.rating_price" value="9" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.rating_price >= 10 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="rating_price" v-model="newReview.rating_price" value="10" />
                        </div>
                    </div>
                    <label for="score_final">🏆 Eindbeoordeling</label>
                    <div class="review-items row">
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.score_final >= 1 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="score_final" v-model="newReview.score_final" value="1" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.score_final >= 2 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="score_final" v-model="newReview.score_final" value="2" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.score_final >= 3 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="score_final" v-model="newReview.score_final" value="3" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.score_final >= 4 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="score_final" v-model="newReview.score_final" value="4" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.score_final >= 5 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="score_final" v-model="newReview.score_final" value="5" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.score_final >= 6 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="score_final" v-model="newReview.score_final" value="6" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.score_final >= 7 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="score_final" v-model="newReview.score_final" value="7" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.score_final >= 8 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="score_final" v-model="newReview.score_final" value="8" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.score_final >= 9 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="score_final" v-model="newReview.score_final" value="9" />
                        </div>
                        <div class="review-item col-1">
                            <div class="star" :class="{ active: newReview.score_final >= 10 }">
                                <font-awesome-icon icon="star"></font-awesome-icon>
                            </div>
                            <input type="radio" name="score_final" v-model="newReview.score_final" value="10" />
                        </div>
                    </div>

                    <label for="commment">Toelichting</label>
                    <textarea maxlength="255" name="commment" v-model="newReview.comment" placeholder="Optionele toelichting"></textarea>

                    <input type="hidden" name="datetime" v-model="newReview.datetime" />

                    <span @click="addReview()" class="btn">OPSLAAN</span>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {

    name: 'NewReview',
    components: {

    },
    data() {
        return {
            errorMessage: "",
            successMessage: "",
            currentTimestamp: "",
            sendSucces: false,
            newReview: {
                datetime: this.currentTimestamp,
                club: "",
                reviewer_name: "",
                rating_pres: "0",
                rating_temp: "0",
                rating_tast: "0",
                rating_struc: "0",
                rating_price: "0",
                score_final: "0",
                comment: ""
            },
            clubs: [],

        }
    },
    methods: {
        addReview: function(){
            //console.log(this.newReview);
            if(this.newReview.reviewer_name == '' || this.newReview.club == '' || this.newReview.rating_pres == 0 || this.newReview.rating_temp == 0 || this.newReview.rating_tast == 0 || this.newReview.rating_struc == 0 || this.newReview.rating_price == 0 || this.newReview.score_final == 0){
                console.log('leeg')
            }else {
                console.log('goed')
                var formData = this.toFormData(this.newReview);
                var newReview = {datetime: "", club: "", reviewer_name: "", rating_pres: "", rating_temp: "", rating_tast: "", rating_struc: "",  rating_price: "", score_final: "", comment: ""}

                axios.post("https://ballentest.nl/php/api.php?action=addReview", formData).then((response)=>{
                    if(response.data.error){
                        console.log(response.data.message);
                    } else{
                        console.log(response.data);
                        this.sendSucces = true;
                        this.newReview = newReview;
                    }
                    this.getCurrentTimestamp();
                });
            }


        },
        toFormData: function(obj){
            var form_data = new FormData();
            for ( var key in obj ) {
                form_data.append(key, obj[key]);
            }
            return form_data;
        },
        getCurrentTimestamp() {
            const date = new Date();
            this.newReview.datetime = date.toISOString();
        },
        getClubs: function(){
            var self = this;
            axios.get("https://ballentest.nl/php/api.php?action=getClubs").then(function(response){
                if(response.data.error){
                    //this.errorMessage = response.data.message;
                    console.log(response.data.message);
                } else{
                    //console.log(response.data.clubs);
                    self.clubs = response.data.clubs;
                }
            });
        },
        backToForm() {
            this.sendSucces = false
        }

    },
    mounted: function(){
        this.getCurrentTimestamp();
        this.getClubs();
    },
    updated() {

    },
    computed: {

  },

}
</script>
