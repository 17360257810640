<template>
  <div class="home">
    <span @click="openPopup()" class="btn main-btn">Beoordeling toevoegen</span>

    <div class="header home">
        <div class="container">
            <h2><b>VVO</b> gehaktballen test<br><i>2023 / 2024</i></h2>
        </div>
        <div class="bottom">
            <div class="container">
                <img src="../assets/img/icon-meatball.png" class="header-icon" />
            </div>
        </div>
    </div>
    <div class="content">
        <Clubs msg="RANKING 🏅" />
    </div>

    <div class="popup" :class="{ active: popup }" id="newArticle">
        <span @click="closePopup()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="red" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
        </span>
        <NewReview />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Clubs from '/src/components/Clubs.vue';
import NewReview from '/src/components/NewReview.vue';


export default {
  name: 'App-home',
    components: {
        Clubs,
        NewReview,
    },
    data() {
        return {
            popup: false
        }
    },
    methods: {
        closePopup() {
            this.popup = false
        },
        openPopup() {
            this.popup = true
        }
    }
}
</script>