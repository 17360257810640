<template>
    <div v-if="AvgScores.score_final > 0" class="col-sm box club-card" @click="toggleDetails()">
       <div class="row">
           <div class="col-10">
               <div class="logo">
                   <img :src="'https://ballentest.nl/img/logos/'+logo" />
               </div>
               <h3>{{ name }} <font-awesome-icon icon="chevron-down" /> </h3>
           </div>
           <div class="col-2">
                <div class="score">
                    <span class="left">{{ AvgScores.score_final }}</span><span class="right">{{ AvgScores.score_final_dec }}</span>
                </div>
           </div>
       </div>
        <div class="details" :class="{ active: showDetails }">
            <h6>Gemiddelden</h6>
            <div class="avg-scores">
                <div class="row">
                    <div class="col-2 nopadding text-center">
                        👀
                        <span>{{ AvgScores.presentation }}</span>

                    </div>
                    <div class="col-2 nopadding text-center">
                        🌡
                        <span>{{ AvgScores.temperature }}</span>

                    </div>
                    <div class="col-2 nopadding text-center">
                        👅
                        <span>{{ AvgScores.tast }}</span>

                    </div>
                    <div class="col-2 nopadding text-center">
                        👨‍🍳
                        <span>{{ AvgScores.structure }}</span>

                    </div>
                    <div class="col-2 nopadding text-center">
                        💰
                        <span>{{ AvgScores.price_quality }}</span>

                    </div>
                </div>
            </div>
            <h6>Beoordelingen</h6>
            <div class="review" v-for=" review in clubReviews" :key="review.id">
                Beoordeeld door: <span>{{ review.reviewer_name }}</span> op <span>{{ review.datetime }}</span>
                <br>
                <span class="item">
                    👀 {{ review.rating_presentation }}
                </span> |
                <span class="item">
                    🌡{{ review.rating_temperature }}
                </span> |
                <span class="item">
                    👅{{ review.rating_tast }}
                </span> |
                <span class="item">
                    👨‍🍳{{ review.rating_structure }}
                </span> |
                <span class="item">
                    💰{{ review.rating_price_quality }}
                </span> |
                <span class="item">
                    🏆 {{ review.score_final }}
                </span>
                <br>
                Toelichting: <span>{{ review.comment }}</span>
            </div>
        </div>
    </div>
    <div v-else class="col-sm box club-card no-score">

        <div class="row">
            <div class="col-9">
                <div class="logo">
                    <img :src="'https://ballentest.nl/img/logos/'+logo" />
                </div>
                <h3>{{ name }} </h3>
            </div>
            <div class="col-3">
                <div class="score">
                    Geen beoordelingen
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';


export default {
    name: 'App-Club',
    props: {
        name: String,
        id: String,
        logo: String
    },
    components: {
      // comps
    },
    data() {
        return {
            clubReviews: [],
            AvgScores: {
                presentation: 0,
                temperature: 0,
                tast: 0,
                structure: 0,
                price_quality: 0,
                score_final: 0,
                score_final_dec: 0,
                score_final_full: 0
            },
            showDetails: false,
            logoSrc: '../assets/img/logos/'+this.logo
        }
    },
    methods: {
        toggleDetails() {
            if(this.showDetails == false) {
                this.showDetails = true
            }else {
                this.showDetails = false
            }
        },
        getClubReviews: function(){
            var self = this;
            axios.get("https://ballentest.nl/php/api.php?action=getClubReviews&clubid="+self.id)
            .then(function(response){
                if(response.data.error){
                    //this.errorMessage = response.data.message;
                    console.log(response.data.message);
                } else{
                    self.clubReviews = response.data.clubReviews;
                    self.CalcAvgScores();
                }
            });

        },
        CalcAvgScores: function(){
            if(this.clubReviews.length > 0){


                var allPresRatings = [];
                var allTempRatings = [];
                var allTastRatings = [];
                var allStrucRatings = [];
                var allPriceRatings = [];
                var allFinalRatings = [];

                this.clubReviews.forEach((review) => {
                    allPresRatings.push(parseInt(review.rating_presentation));
                    allTempRatings.push(parseInt(review.rating_temperature));
                    allTastRatings.push(parseInt(review.rating_tast));
                    allStrucRatings.push(parseInt(review.rating_structure));
                    allPriceRatings.push(parseInt(review.rating_price_quality));
                    allFinalRatings.push(parseInt(review.score_final));
                })

                var sumPres = allPresRatings.reduce((acc, item) => acc + item, 0);
                var avgPres = sumPres / allPresRatings.length;
                this.AvgScores.presentation = avgPres.toFixed(1);

                var sumTemp = allTempRatings.reduce((acc, item) => acc + item, 0);
                var avgTemp = sumTemp / allTempRatings.length;
                this.AvgScores.temperature = avgTemp.toFixed(1);

                var sumTast = allTastRatings.reduce((acc, item) => acc + item, 0);
                var avgTast = sumTast / allTastRatings.length;
                this.AvgScores.tast = avgTast.toFixed(1);

                var sumStruc = allStrucRatings.reduce((acc, item) => acc + item, 0);
                var avgStruc = sumStruc / allStrucRatings.length;
                this.AvgScores.structure = avgStruc.toFixed(1);

                var sumPrice = allPriceRatings.reduce((acc, item) => acc + item, 0);
                var avgPrice = sumPrice / allPriceRatings.length;
                this.AvgScores.price_quality = avgPrice.toFixed(1);

                var sumFinal = allFinalRatings.reduce((acc, item) => acc + item, 0);
                var avgFinal = sumFinal / allFinalRatings.length;
                this.AvgScores.score_final = avgFinal.toFixed(1).split(".")[0];
                this.AvgScores.score_final_dec = avgFinal.toFixed(1).split(".")[1];
                this.AvgScores.score_final_full = avgFinal.toFixed(1);

                axios.post("https://ballentest.nl/php/api.php?action=updateclub&clubid="+this.id+"&avgscore="+this.AvgScores.score_final_full)
                .then(function(response){
                    if(response.data.error){
                        console.log(response.data.message)
                    } else{
                        // console.log(response.data.message)
                    }
                });

            } else {
                console.log('geen reviews')
            }
        },

    },
    mounted: function(){
        this.getClubReviews();
    },
    updated() {
        //this.getClubs();
    }

}
</script>
