<template>
    <div class="container">
        <div class="row">
            <div class="col-sm">
                <div class="row">
                    <div class="col-10">
                        <h1 >{{ msg }}</h1>
                    </div>
                    <div class="col-2 text-right reload" @click="reloadPage()">
                        <font-awesome-icon :icon="['fas', 'sync-alt']" />
                    </div>
                </div>

                <p>{{errorMessage}}</p>
                <p>{{successMessage}}</p>

                <div v-for=" club in clubsSort" :key="club.id">
                    <Club :id="club.id" :name="club.name" :logo="club.logo_file" />
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Club from '/src/components/Club.vue';

export default {
    name: 'App-Clubs',
    props: {
        msg: String
    },
    components: {
      // comps
      Club
    },
    data() {
        return {
            errorMessage: "",
            successMessage: "",
            clubs: [],
            // clubsSort: [],
            reviews: [],
        }
    },
    methods: {
        getClubs: function(){
            var self = this;
            axios.get("https://ballentest.nl/php/api.php?action=getClubs")
            .then(function(response){
                if(response.data.error){
                    //this.errorMessage = response.data.message;
                    console.log(response.data.message);
                } else{
                    self.clubs = response.data.clubs;
                }
            });
        },
        getReviews: function(){
            var self = this;
            axios.get("https://ballentest.nl/php/api.php?action=getReviews")
            .then(function(response){
                if(response.data.error){
                    //this.errorMessage = response.data.message;
                    console.log(response.data.message);
                } else{
                    self.reviews = response.data.reviews;
                }
            });
        },
        reloadPage() {
            location.reload();
        },
        updateUser: function(){
            //console.log(this.newUser);
            var formData = this.toFormData(this.clickedUser);

            axios.post("https://ballentest.nl/app01/crud/api.php?action=update", formData)
            .then(function(response){
                this.clickedUser = {};
                if(response.data.error){
                    this.errorMessage = response.data.message;
                } else{
                    this.successMessage = response.data.message;
                    this.getAllUsers();
                }
            });
        },

        deleteUser: function(){
            //console.log(this.newUser);
            var formData = this.toFormData(this.clickedUser);

            axios.post("https://ballentest.nl/app01/crud/api.php?action=delete", formData)
            .then(function(response){
                this.clickedUser = {};
                if(response.data.error){
                    this.errorMessage = response.data.message;
                } else{
                    this.successMessage = response.data.message;
                    this.getAllUsers();
                }
            });
        },

        selectUser(user){
            this.clickedUser = user;
        },
        clearMessage: function(){
            this.errorMessage = "";
            this.successMessage = "";
        },

    },
    mounted: function(){
        this.getReviews();
        this.getClubs();
    },
    updated() {
        //this.getClubs();
    },
    computed: {
        clubsSort(){
            return this.clubs.slice().sort((a, b) => b.avgscore - a.avgscore);
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
