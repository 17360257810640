<template>
    <div id="app">
        <nav :class="{ open: menu }" >
            <div @click="closeMenu()">
                <router-link to="/">Home</router-link>
                <router-link to="/reports">Rapportages</router-link>
            </div>
        </nav>
        <span class="menu-toggle" @click="toggleMenu()">
            <font-awesome-icon v-if="menu == false" icon="bars" />
            <font-awesome-icon v-else icon="times" />
        </span>
        <router-view />
    </div>
</template>

<script>
export default {
    components: {

    },
    data() {
        return {
            menu: false
        }
    },
    methods: {
        toggleMenu() {
            if(this.menu == false) {
                this.menu = true
            }else {
                this.menu = false
            }
        },
        closeMenu() {
            this.menu = false
        }
    }
}
</script>

<style lang="scss">
    @import 'assets/css/bootstrap-reboot.min.css';
    @import 'assets/css/bootstrap-grid.min.css';
    @import 'assets/css/bootstrap.min.css';
    @import 'assets/css/base.scss';
    @import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700;800;900&display=swap');
</style>
